import { FC, Fragment, useContext, useEffect, useState } from "react";
// import Footer from "../Footer/Footer";
// import Header from "../Header/Header";
// import NavigationBurger from "../Header/navigation-menu-burger";

import dynamic from "next/dynamic";
import useWindowSize from "../../hooks/useWindowSize";
import FilterMobile from "../filter-mobile/filter-mobile";
import { StaticContext } from "../../context/static-context/static-context";

const Footer = dynamic(() => import("../Footer/Footer"));
const Header = dynamic(() => import("../Header/Header"));
const NavigationBurger = dynamic(
	() => import("../Header/navigation-menu-burger"),
);

const Layout: FC = ({ children }) => {
	const [showBurgerMenu, setShowBurgetMenu] = useState(false);
	const [loaderMobileFilter, setLoaderMobileFilter] = useState(false);
	const { width } = useWindowSize();
	const contextData = useContext(StaticContext);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoaderMobileFilter(true);
		}, 3000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Fragment>
			<Header
				showBurgerMenu={showBurgerMenu}
				setShowBurgetMenu={setShowBurgetMenu}
			/>
			<NavigationBurger
				showBurgerMenu={showBurgerMenu}
				setShowBurgetMenu={setShowBurgetMenu}
			/>
			{width < 570 && loaderMobileFilter && <FilterMobile />}
			{children}
			<Footer />
		</Fragment>
	);
};

export default Layout;
